const secureRoutes = ['ecc.*', 'tp.*', 'messages.*', 'notifications.*', 'profile.*', 'user.*']

export const isGuestRoute = () => {
  return secureRoutes.every((secureRoute) => !route().current(secureRoute))
}

export const isSecureRoute = () => {
  return secureRoutes.some((secureRoute) => route().current(secureRoute))
}

export const getRouteNameForUser = (user, name) => {
  return user.has_ecc_role ? `ecc.${name}` : `tp.${name}`
}
